body {
  overflow-x: inherit;
}

.canvass-graph {
  width: 100%;
  height: 100%;
}

.title {
  text-align: center;
}

.App {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.legend {
  padding-bottom: 15px;
}

.legend-item {
  user-select: none;
  padding-left: 10px;
}

.legend-list {
  list-style-type: none;
}

.legend-list > li {
  padding-left: 15px;
  display: inline-block;
}

h3 {
  padding: 10px 0 10px 0;
  font-size: 20px;
}

.loader-container {
  position: relative;
  margin: 1rem 0;
  padding: 1em 1em;
  min-height: 100px;
}

.loader-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: inherit;
  box-shadow: none;
  border: 1px solid pink;
  background-color: mediumseagreen;
}

table {
  border-spacing: 0;
  border: 1px solid #ededed;
  table-layout: fixed;
}

table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: rgba(250, 250, 250, 1);
  padding: 10px 5px;
}

table th,
table td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #ededed;
  border-right: 1px solid #ededed;
  text-align: center;
}

table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
}

table tbody th {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: rgba(250, 250, 250, 1);
}
table thead tr th {
  padding-top: 3em;
}

.sticky-table-container {
  width: 100%;
  overflow-x: auto;
  height: calc(100vh - 50px);
}

table tbody tr:nth-child(odd) {
  background-color: rgba(255, 255, 255, 1);
}

table tr:nth-child(even) {
  background-color: rgba(250, 250, 250, 1);
}

@media all {
  .weird-tick {
    display: block;
  }
  .weirder-tick {
    display: none;
  }
}

@media all and (max-width: 1400px) {
  .weird-tick {
    display: none;
  }
  .weirder-tick {
    display: block;
  }
}
